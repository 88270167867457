/* Start style reset to be consistant across browsers */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

html {
	font-family: Helvetica;
	font-size: 16px;
}

html * {
	box-sizing: border-box;
}

body {
	background: #F4F1F3;
}

h1 { font-size: 2em; }
h2 { font-size: 1.5em; }
h3 { font-size: 1.17em; }
h4 { font-size: 1.12em; }
h5 { font-size: .83em; }
h6 { font-size: .75em; }

a {
	text-decoration: none;
	color: #3d9991;
}

strong {
	font-weight: bold;
}

.app {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.container {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 1020px;
	margin: 0 auto;
	padding: 20px;
}

.btn {
	display: block;
	padding: 15px 26px;
	border: none;
	border-radius: 300px;
	letter-spacing: 2px;
	text-transform: uppercase;
	-webkit-appearance: none;
	outline: none;
	cursor: pointer;
}

.btn-primary {
	color: #fff;
	background: #36b3a8;
}

.btn-primary:hover {
	background: rgba(54,179,168,.8);
}

.btn-secondary {
	border: 2px solid #fff;
	background: none;
}

.btn-secondary:hover {
	background: #fff;
}

.hidden {
	display: none !important;
}

.page-title {
	color: rgba(148,25,25,.9);
}

/* Forms */
.field + .field {
	margin-top: 20px;
}

.field.submit {
	display: flex;
	align-items: center;
}

.field.submit .btn {
	margin-right: 15px;
}

.radio-group p {
	margin-bottom: 10px;
}

.radio-group label {
	margin-right: 5px;
}

.radio-group label input {
	margin-right: 5px;
}

input:not([type="radio"]):not([type="checkbox"]) {
	padding: 10px;
	border: 1px solid rgba(0,0,0,0.2);
	border-radius: 4px;

	font-size: 1em;

	-webkit-appearance: none;
	outline: none;
}

.ng-dirty {
	border-color: #f4a00e !important;
}

.ng-touched.ng-invalid {
	border-color: #c60b0b !important;
}

.ng-touched.ng-valid {
	border-color: #05aa07 !important;
}

.form-success {
	color: #00c500;
}

.form-error {
	color: #c60b0b;
}

.disabled {
	background-color: #698c89;
}


.dropdown-arrow {
	display: inline-block;
	position: relative;
	background-color: #fff;
	text-align: left;
}

.dropdown-arrow:before,
.dropdown-arrow:after {
	content: '';
	position: absolute;
	background-color: inherit;
}

.dropdown-arrow,
.dropdown-arrow:before,
.dropdown-arrow:after {
	width:  6px;
	height: 6px;
	border-top-right-radius: 30%;
}

.dropdown-arrow {
	transform: skewX(-30deg) scale(1,.866);
}
.dropdown-arrow:before {
	transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
}
.dropdown-arrow:after {
	transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
}
